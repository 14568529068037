import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    uid: "",
    // 題目
    question: [],
  },
  getters: {
  },
  mutations: {
    // 设置题目
    SET_QUESTION(state, params) {
      for (let i = 0; i < state.question.length; ++i) {
        if (state.question[i].recordId == params.recordId && state.question[i].id == params.id) {
          state.question[i] = params;
        }
      }
      // 判断是否有这个题目的标识id
      const isRecordId = state.question.findIndex(item => item.recordId == params.recordId && item.id == params.id);
      if (isRecordId == -1) {
        // 添加题目进入缓存
        state.question.push(params);
      }
      if (state.question.length == 0) state.question.push(params);

    },
    // 设置uid
    SET_UID(state, params) {
      state.uid = params
    },
    // 清空题目
    CLEAR_QUESTION(state, params) {
      const index = state.question.findIndex(item => item.recordId == params.task_id && item.id == params.id);
      state.question.splice(index, 1);
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.localStorage, // 或者 localStorage
  })]
})
