import Vue from 'vue';
//  引入你需要的vant组件
import { Picker } from 'vant';
Vue.use(Picker)
import { Loading } from 'vant';
Vue.use(Loading)
import { Popup } from 'vant';

Vue.use(Popup)
//  引入组件样式
import 'vant/lib/index.css';
