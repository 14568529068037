import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("@/views/index.vue")
  },
  {
    path: '/evaluateReport',
    name: 'evaluateReport',
    component: () => import("@/views/evaluationReport/index.vue")
  },
  {
    path: '/evaluateReportDetail',
    name: 'evaluateReportDetail',
    component: () => import("@/views/evaluationReportDetail/index.vue")
  }



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
